//variables pour site Core
var retina = window.devicePixelRatio > 1;

toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': false,
    'progressBar': false,
    'positionClass': 'toast-top-center',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
};


//IE
if ($.browser.msie) {
	$('html').addClass('ie').addClass('ie'+parseInt($.browser.version,10));
}


//functions qu'on call pour site Core
var emploisuccess = function() {
	$('.js-emploiForm').fadeOut(500,function() {$('.emploisuccess').fadeIn();});
};

var emploierror = function() {
	$('.js-emploiForm .btn').fadeIn();
	$('.js-emploiForm').append('<p class="text-error">Une erreur est survenue, veuillez réessayer plus tard.</p>');
};


function toast(type, message, timeout) {
    timeout = timeout || 1000;
    toastr.options['timeOut'] = timeout;
    toastr[type](message)
}
function toast_them(toasts) {
    for (var type in toasts) {
        for (var key in toasts[type]) {
            toast(type, toasts[type][key], 5000);
        }
    }
}

function reenableButtons($form) {
    $form = $form || $(document);
    var $buttons = $form.find('button[type=submit]');
    $buttons.each(function() {
        var $button = $(this);
        $button.removeClass('disabled');
        if ($button.data('text')) {
            $button.text($button.data('text'));
        }
    });
}
function ajaxCall(url, callback, callbackArgs) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }
    url += '&ajax';
    $.ajax({
        url: url,
        type: 'GET',
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function(response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if (response.message == 'reload') {
                    location.href = location.href;
                } else {
                    if (callback) {
                        if (window[callback]) {
                            callback = window[callback];
                        }
                        var args = [response];
                        args = args.concat(callbackArgs);
                        callback.apply(null, args);
                    }
                }
            } else if (response.message) {
                toast('error', response.message);
            }
            if (response.new_url) {
                window.history.pushState(response, "", response.new_url);
            }
        }
    });
}
function sendFormAjax($form, method, callback, callbackArgs) {
    var formData = null;
    var processData = true;
    var contentType = null;
    method = method ? method : 'POST';
    if ($form.find('input[type=file]').length) {
        if (typeof IE9 !== 'undefined' && IE9) {
            // Envoyer des fichiers dans IE9 et moins �a marche pas en ajax
            if (!$form.parents('body').length) {
                $(document.body).append($form);
            }
            $form.unbind('submit');
            $form.unbind();
            $form[0].onSubmit = false;
            $form.submit();
            return false;
        } else {
            processData = false;
            contentType = false;
            formData = new FormData($form[0]);
        }
    } else {
        // Si on n'a pas de fichier � envoyer, on va juste faire un serialize bin normal
        formData = $form.serialize();
        contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
    }
    var action = $form.attr('action');
    if (action.indexOf('?') < 0) {
        action += '?';
    }
    action += '&lan'+ getTheCurrentLang() +'&ajax';
    $.ajax({
        url: action,
        type: method,
        data: formData,
        dataType: 'json',
        processData: processData,
        contentType: contentType,
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
            reenableButtons($form);
        },
        success: function (response) {
            if (callback) {
                if (window[callback]) {
                    callback = window[callback];
                }
                var args = [response];
                args = args.concat(callbackArgs);
                callback.apply(null, args);
            } else {
                if (response.result) {
                    if (response.replaceHtml) {
                        $form.replaceWith($(response.replaceHtml));
                    }
                }
            }
        }
    });
}
function reDisplayForm($form) {
    if (typeof $form == 'string') { $form = $($form); }
    if ($form.is(':hidden')) {
        resetFormErrors($form);
        $form[0].reset();
        $form.parent().find('.Alert.has-success').addClass('hide');
        reenableButtons($form);
        $form.show();
    }
}
function resetFormErrors($form) {
    $form.find('.Alert.has-error').empty();
    $form.find('.Alert').addClass('hide');
    $form.find('.js-error').remove();
    $form.find('.FormElement.has-error').removeClass('has-error')
}
function displaySuccess($form) {
    if (typeof $form == 'string') { $form = $($form); }
    resetFormErrors($form);
    var $alert = $form.next('.Alert.has-success');
    if (!$alert.length) {
        $alert = $form.hide().parent().find('.Alert.has-success');
    }
    $form.hide();
    $alert.removeClass('hide');
    $('html, body').animate({
        scrollTop: $form.parent().offset().top - 100
    }, 600);
}
function displayErrors($form, messages) {
    if (typeof $form == 'string') { $form = $($form); }
    $form = $form || $(document);
    resetFormErrors($form);
    var focusIt = true;
    for (var name in messages) {
        if (name == 'global') {
            $form.find('.Alert.has-error').removeClass('hide').append(messages[name]);
            $('html, body').animate({
                scrollTop: $form.parent().offset().top
            }, 600);
        } else {
            var $ele = $form.find('*[name=' + name + ']');
            if ($ele.length) {
                if (focusIt && ($ele[0].offsetParent !== null)) {
                    $ele.focus();
                    focusIt = false;
                }
                var $formElement = $ele.closest('.FormElement');
                if ($formElement.length) {
                    $formElement.addClass('has-error');
                    $formElement.after('<label for="'+ $ele.attr('id') + '" class="js-error">' + messages[name] + '</label>')
                }
            }
        }
    }
}

function initSearchPagination() {
    $('#search_pagination').find('a').on('click touchstart', function(e) {
        var $this = $(this);
        loadSearchResults($this.attr('href'), {});
        e.preventDefault();
    });
}
function loadSearchResults(url, data) {
    if (url.indexOf('?') < 0) {
        url += '?';
    }
    url += '&ajax';
    $.ajax({
        url: url,
        type: 'POST',
        data: data,
        dataType: 'json',
        async: true,
        cache: false,
        beforeSend: function () {
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert('Une erreur est survenue, veuillez réessayer plus tard.');
        },
        success: function (response) {
            if (!response.error) {
                $('#search_results').html(response.html);
                $('#search_pagination').html(response.pagination);

                initSearchPagination();
                $('html,body').animate({
                    scrollTop: $('#search_results').offset().top
                }, 500);
            } else {
                if (response.message) {
                    displayErrors($('#search-form'), { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($('#search-form'), response.messages);
                }
            }
            reenableButtons($('#search-form'));
        }
    });
}

//// Acolyte core -- TODO
function joss(msg) {
    if($("body").hasClass("isdev")) {
        console.log(msg);
    }
}


//gestion des events jQuery pour site Core
$(function() {

	$('.js-form').on('submit', function(e){
        var $form = $(this);
        resetFormErrors($form);

        $form.find('input[name=special_email]').val('');
        sendFormAjax($form, 'POST', function (response) {
            if (response.toast) {
                toast_them(response.toast);
            }
            if (!response.error) {
                if (response.url) {
                    location.href = response.url;
                } else if (response.message == 'reload') {
                    location.href = location.href;
                } else {
                    displaySuccess($form);
                }
            } else {
                reenableButtons($form);
                if (response.message) {
                    displayErrors($form, { 'global': response.message });
                } else if (response.messages) {
                    displayErrors($form, response.messages);
                }
            }
        });
        e.preventDefault();
	});

	$('#bt-logout').on('click',function() {
		$.getJSON('/json?module=user&method=logout', function(data) {
			window.top.location = data.url;
		});
		return false;
	});
	
	$('#search-form').on('submit', function (e) {
        var $this = $(this);
        resetFormErrors($this);
        loadSearchResults($this.attr('action'), $this.serialize());
        e.preventDefault();
    });
    initSearchPagination();

	var device = Detect();
    if (device=='smartphone') {
        $('body').addClass('mobile');
    }

    if (device=='desktop') {
        $('body').addClass('computer');
    }

	$("body").on('click','li.js-noclick a',function(){
		$(this).parent().toggleClass('in');
		return false;
	});

	$(".scrollto").click(function() { // Mettre class .scrollto sur le bouton et mettre attribut data-target="#id" et data-duration="1000"
		var scrolltarget = $(this).data('target');
	    $('html, body').animate({
	        scrollTop:$(scrolltarget).offset().top
	    }, $(this).data('duration'));
	    return false;
	});

	//svgeezy.init();

	$('body').on('touchstart.dropdown', '.dropdown-menu', function (e) { e.stopPropagation(); }); // iPad fix for unclickable nav/subnav

	$('.carousel').carousel({
	  interval: 5000
	});
	
	$("button.close").on('click',function(){
		$(this).parent().slideUp();
	});

	if ($.browser.msie && $.browser.version<9) {
		$('header nav ul > li:last-child').addClass('last').prev().addClass('last');
	}

	if ($('.fancybox').length>0) {
		$('.fancybox').magnificPopup({ 
		  type: 'image',
		  gallery:{enabled:true},
		  callbacks: {
    
		    buildControls: function() {
		      // re-appends controls inside the main container
		      this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
		    }
		    
		  }
		});
	}

	if ($('.fancyboxvideo').length>0) {
		$('.fancyboxvideo').magnificPopup({ 
		  type: 'iframe',
		  gallery:{enabled:true}

		});
	}

	$(window).load(function() {
        $("body").removeClass("preload");
    });

	/* Mobile Nav Events START */
	var el = $('.mobile-nav > ul'),
		curHeight = el.height(),
		autoHeight = 0,
		subHeight = 0;
		menuHeight = 40;

	$('.bt-menu, .btn-menu').click(function(){
		    autoHeight = el.css('height', 'auto').height();
		if(!el.hasClass('open')){
			el.height(curHeight).animate({height: autoHeight}, 400);
		}else{
			el.height(autoHeight).animate({height: curHeight}, 400);
		}
		//console.log('click!');
		el.toggleClass('open');
		return false;
	});

	$('.mobile-nav > ul > li > a').click(function(){
		if($(this).parent().children().length > 1){
			$(this).next().addClass('active');
			subHeight = $(this).next().find('ul').height()+menuHeight;
			if(subHeight>autoHeight){
				el.height(autoHeight).animate({height: subHeight}, 200);
			}
			return false;
		}
	});

	$('.mobile-nav > ul > li > div b').click(function(){
		$('.mobile-nav div').removeClass('active');
		if(subHeight>autoHeight){
			el.height(subHeight).animate({height: autoHeight}, 200);
		}
		return false;
	});
	/* Mobile Nav Events END */

	$(window).resize(function() {
		$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());
	});
	$('.wrapper-facebook .facebook .fb-inner').css('height',$(window).height());

	$(window).scroll(function(event) {
		var h = parseInt($('header').css('height'));
		if ($(window).scrollTop()>=h) $('.computer .nav-fixed').addClass('active');
		else $('.computer .nav-fixed').removeClass('active');
	});

	$(window).resize(function() {
		$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);
	});
	$('.home.computer header .wrapper-slider, .home.computer header .wrapper-slider .item').css('height',$(window).height()-40);

	$(".select-wrapper select").change(function(){
        $(this).parent().find('span').text($(this).parent().find("select option:selected").text());
    });

	$('.custom-accordion').click(function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active').next().slideUp();
        }else{
            //$('.custom-accordion.active').removeClass('active').next().slideUp();
            $(this).addClass('active').next().slideDown();
        }
        return false;
    });



    $(window).on('load', function() {
    	// Check all tables. You may need to be more restrictive.
	    $('table.responsive-table').each(function() {
	        var element = $(this);
	        // Create the wrapper element
	        var scrollWrapper = $('<div />', {
	            'class': 'scrollable',
	            'html': '<div />' // The inner div is needed for styling
	        }).insertBefore(element);
	        // Store a reference to the wrapper element
	        element.data('scrollWrapper', scrollWrapper);
	        // Move the scrollable element inside the wrapper element
	        element.appendTo(scrollWrapper.find('div'));
	        // Check if the element is wider than its parent and thus needs to be scrollable
	        if (element.outerWidth() > element.parent().outerWidth()) {
	            element.data('scrollWrapper').addClass('has-scroll');
	        }
	        // When the viewport size is changed, check again if the element needs to be scrollable
	        $(window).on('resize orientationchange', function() {
	            if (element.outerWidth() > element.parent().outerWidth()) {
	                element.data('scrollWrapper').addClass('has-scroll');
	            } else {
	                element.data('scrollWrapper').removeClass('has-scroll');
	            }
	        });
	    });
	});

});


/**
 * Module for displaying "Waiting for..." dialog using Bootstrap
 *
 * @author Eugene Maslovich <ehpc@em42.ru>
 * http://bootsnipp.com/snippets/featured/quotwaiting-forquot-modal-dialog
 * https://github.com/ehpc/bootstrap-waitingfor
 */
var waitingDialog = (function ($) {

    // Creating modal dialog's DOM
	var $dialog = $(
		'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:15%; overflow-y:visible;">' +
		'<div class="modal-dialog modal-m">' +
		'<div class="modal-content">' +
			'<div class="modal-header"><h3 style="margin:0;"></h3></div>' +
			'<div class="modal-body">' +
				'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
			'</div>' +
		'</div></div></div>');

	return {
		/**
		 * Opens our dialog
		 * @param message Custom message
		 * @param options Custom options:
		 * 				  options.dialogSize - bootstrap postfix for dialog size, e.g. "sm", "m";
		 * 				  options.progressType - bootstrap postfix for progress bar type, e.g. "success", "warning".
		 */
		show: function (message, options) {
			// Assigning defaults
			var settings = $.extend({
				dialogSize: 'm',
				progressType: ''
			}, options);
			if (typeof message === 'undefined') {
				message = 'Loading';
			}
			if (typeof options === 'undefined') {
				options = {};
			}
			// Configuring dialog
			$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
			$dialog.find('.progress-bar').attr('class', 'progress-bar');
			if (settings.progressType) {
				$dialog.find('.progress-bar').addClass('progress-bar-' + settings.progressType);
			}
			$dialog.find('h3').text(message);
			// Opening dialog
			$dialog.modal();
		},
		/**
		 * Closes dialog
		 */
		hide: function () {
			$dialog.modal('hide');
		}
	};

})(jQuery);

/**
 * Affichage des popups
 */
function closePopup($popup) {
    $('.Popup_overlay').remove();
    $popup.hide();
}
function showPopup($popup) {
    var popups_shown = $.cookie('popups_shown');
    if (popups_shown) {
        popups_shown = JSON.parse(popups_shown);
    }
    if (popups_shown instanceof Object && !$.isArray(popups_shown)) {
        popups_shown = Object.keys(popups_shown).map(function(k) { return popups_shown[k] });
    } else if (typeof popups_shown == 'undefined') {
        popups_shown = [];
    }
    if ($popup.data('once') && popups_shown.indexOf($popup.data('id')) > -1) {
        $popup.data('shown', 1);
    }
    if ($popup.data('shown')) {
        return;
    }
    $('.Popup_overlay').remove();
    var $overlay = $('<div>');
    $overlay.addClass('Popup_overlay');
    $overlay.css({
        'z-index': 9999,
        'position': 'fixed',
        'background-color': '#000000',
        'opacity': '0.5',
        'width': '100%',
        'height': '100%'
    });
    $overlay.on('click', function(e) {
        closePopup($popup);
        e.stopPropagation();
        e.preventDefault();
    });
    $popup.find('a.Popup_close').on('click', function(e) {
        closePopup($popup);
        e.stopPropagation();
        e.preventDefault();
    });
    $('body').prepend($overlay);
    $popup.css({
        'z-index': 10000,
        'position': 'fixed',
        'min-width': '50%',
        'min-height': '50%',
    });
    $popup.show();
    $popup.data('shown', 1);
    if ($popup.data('once')) {
        popups_shown.push($popup.data('id'));
        $.cookie(
            'popups_shown', JSON.stringify(popups_shown), {
                path: '/'
            }
        );
    }
}
var scrollTop = 0;
$(function() {
    $('.Popup').each(function(e) {
        var $popup = $(this);
        if ($popup.hasClass('scrollup')) {
            $(window).on('scroll', function(e) {
                var currentScroll = $(this).scrollTop();
                console.log(currentScroll + ' vs ' + scrollTop);
                if (currentScroll < scrollTop - 10) {
                    setTimeout(function() { showPopup($popup); }, $popup.data('seconds') ? parseInt($popup.data('seconds')) * 1000 : 0);
                }
                scrollTop = currentScroll;
            });
        } else {
            setTimeout(function() { showPopup($popup); }, $popup.data('seconds') ? parseInt($popup.data('seconds')) * 1000 : 0);
        }
    });
});
/**
 * Formulaire login
 */
$(function() {
    $('#show-login').on('click', function(e) {
        e.preventDefault();
        $('#forgot-form').hide();
        $('#login-form').show();
    });
    $('#show-forgot').on('click', function(e) {
        e.preventDefault();
        $('#login-form').hide();
        $('#forgot-form').show();
    });
});